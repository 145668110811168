import React, { useState, useEffect } from "react";

const DemoChatWindow = ({ messages }) => {
  const [messageInput, setMessageInput] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const unreadMessages = messages.filter((message) => !message.read);
    setUnreadCount(unreadMessages.length);
  }, [messages]);

  useEffect(() => {
    const messageElements = document.querySelectorAll(".message");
    messageElements.forEach((message, index) => {
      setTimeout(() => {
        message.style.opacity = 1;
      }, index * 1000); // Adjust the delay time as per your preference
    });
  }, [messages]);

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleSendMessage = () => {
    console.log("Sending message:", messageInput);
    setMessageInput("");
  };

  return (
    <div className="flex-grow bg-gray-800 bg-opacity-90 p-4 rounded-lg shadow-md mt-4 mx-4 overflow-hidden">
      <div className="flex">
        <div className="w-2/4 pr-4">
          <div className="mb-4">
            <h2 className="text-lg text-gray-200 font-semibold mb-2">
              Friends
            </h2>
            <ul className="text-sm text-gray-300">
              <li className="mb-1">Friend 1</li>
              <li className="mb-1">Friend 2</li>
              <li className="mb-1">Friend 3</li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg text-gray-200 font-semibold mb-2">Groups</h2>
            <ul className="text-sm text-gray-300">
              <li className="mb-1">Group 1</li>
              <li className="mb-1">Group 2</li>
              <li className="mb-1">Group 3</li>
            </ul>
          </div>
        </div>
        <div className="w-3/4">
          <div
            className="chat-messages h-96 overflow-y-auto scrollbar-hide"
            style={{ scrollbarWidth: "none" }}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`text-left mb-2 animate__animated animate__fadeIn message`}
                style={{ opacity: 0, animationDelay: `${index * 1}s` }}
              >
                <span
                  className={`inline-block rounded-lg p-2 ${
                    message.read
                      ? "bg-gray-600 text-gray-200"
                      : "bg-gray-700 text-gray-300"
                  }`}
                >
                  {message.text}
                </span>
                <br />
                <span className="text-xs text-gray-400">
                  {new Date(message.timestamp).toLocaleString()}
                </span>
              </div>
            ))}
          </div>
          <div className="flex mt-4">
            <input
              type="text"
              placeholder="Type your message..."
              className="flex-grow rounded-l-lg p-2 border border-gray-600 focus:outline-none focus:border-blue-500 text-black"
              value={messageInput}
              onChange={handleInputChange}
            />
            <button
              className="bg-gray-600 text-gray-200 px-4 py-2 rounded-r-lg"
              onClick={handleSendMessage}
            >
              Send
            </button>
          </div>
          {unreadCount > 0 && (
            <div className="text-xs text-gray-400 mt-2">
              {unreadCount} unread {unreadCount === 1 ? "message" : "messages"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoChatWindow;
