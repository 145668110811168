import React from "react";

const HappyCustomers = () => {
  return (
    <div
      id="happy-customers"
      className="section bg-gradient-to-r text-white py-12"
    >
      <h2 className="text-3xl lg:text-4xl font-bold mb-8 text-center">
        Happy Customers 😊
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {/* Customer 1 */}
        <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl flex flex-col items-center justify-center">
          <span className="text-6xl mb-4">👩‍🦰</span>
          <h3 className="text-2xl font-semibold text-white mb-4">Customer 1</h3>
          <p className="text-gray-300">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et elit
            vel ante varius suscipit. Sed auctor ex non ipsum convallis.
          </p>
        </div>
        {/* Customer 2 */}
        <div className="bg-gray-900 bg-opacity-50 p-6 rounded-lg shadow-xl flex flex-col items-center justify-center">
          <span className="text-6xl mb-4">🧑‍💼</span>
          <h3 className="text-2xl font-semibold text-white mb-4">Customer 2</h3>
          <p className="text-gray-300">
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Duis eget leo eu magna varius dictum nec
            eget turpis.
          </p>
        </div>
        {/* Customer 3 */}
        <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg shadow-xl flex flex-col items-center justify-center">
          <span className="text-6xl mb-4">👨‍💻</span>
          <h3 className="text-2xl font-semibold text-white mb-4">Customer 3</h3>
          <p className="text-gray-300">
            Vestibulum rhoncus, purus et maximus condimentum, nulla nunc luctus
            turpis, eget feugiat nunc tortor nec elit. Integer euismod est eget
            eros varius scelerisque.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HappyCustomers;
