// src/Components/Sidebar.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SideBar.css";
const Sidebar = () => {
  const [users, setUsers] = useState([]);

  // const friends = [
  //   { id: 1, name: "Alice" },
  //   { id: 2, name: "Bob" },
  //   { id: 3, name: "Charlie" },
  //   { id: 4, name: "David" },
  //   { id: 5, name: "Eve" },
  // ];

  const groups = ["Group 1", "Group 2", "Group 3"];
  useEffect(() => {
    // Fetch users from the API
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "http://43.204.128.28/api/Chat/users"
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);
  return (
    <div className="bg-black bg-opacity-40 h-full w-64 p-4 overflow-y-auto">
      <div>
        <h2 className="text-white text-lg font-semibold mb-2">users</h2>
        <ul>
          {users.map((user) => (
            <li
              key={user._id}
              className="text-white mb-2 cursor-pointer hover:bg-gray-800 rounded-lg p-2"
            >
              {user.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4 border-t border-gray-600 pt-4">
        <h3 className="text-white text-lg font-semibold mb-2">Groups</h3>
        <ul>
          {groups.map((group, index) => (
            <li
              key={index}
              className="text-white mb-2 cursor-pointer hover:bg-gray-800 rounded-lg p-2"
            >
              {group}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
