import React, { useState, useEffect } from "react";

const ChatWindow = () => {
  const currentUserId = "Gaurav&rana&20240528154809151";
  const [inputValue, setInputValue] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = async () => {
    try {
      const response = await fetch("http://43.204.128.28/api/chat/getall");
      const data = await response.json();
      setApiResponse(data);

      const chatNode = data.chat_nodes.find(
        (node) =>
          node._id ===
          "0bfcfe42002baf0c05400f2d56d4e131e0f44f59c2f4c62306e1016d95a1ed62"
      );

      if (chatNode) {
        const filteredMessages = chatNode.Messages.map((message) => ({
          id: message.MessageId,
          sender: message.SenderId,
          text: message.Content,
          timestamp: message.Timestamp,
        }));
        setMessages(filteredMessages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };

  const handleMessageChange = (e) => {
    setInputValue(e.target.value);
  };

  const sendMessage = () => {
    if (inputValue.trim() !== "") {
      const newMessage = {
        id: Date.now(), // Temporary ID
        Content: inputValue,
        SenderCode: currentUserId,
        ReceiverCode: "swastik&Rana&20240528144250949",
        sender: currentUserId,
        text: inputValue,
        timestamp: new Date().toISOString(), // Temporary timestamp
      };
      // Optimistically update the UI
      setMessages([...messages, newMessage]);
      setInputValue(""); // Clear the input after sending the message

      // Add logic to send the message to the server
      fetch("http://43.204.128.28/api/Chat/send-message", {
        mode: 'no-cors',
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newMessage),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Update the message with the ID from the server
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === newMessage.id ? { ...msg, id: data.id } : msg
            )
          );
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          // Optionally: Handle error by removing the message or showing an error
        });
    }
  };

  const handleEmojiSelect = (emoji) => {
    setInputValue(inputValue + emoji);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  // Define a list of emojis
  const emojis = [
    "😀",
    "😁",
    "😂",
    "😃",
    "😄",
    "😅",
    "😆",
    "😇",
    "😈",
    "😉",
    "😊",
  ];

  return (
    <div className="flex-grow bg-blue-400 bg-opacity-50 p-8 rounded-lg shadow-md mt-4 mx-4 overflow-hidden">
      <div
        className="chat-messages h-64 overflow-y-auto scrollbar-hide"
        style={{ scrollbarWidth: "none" }}
      >
        {messages.map((message) => (
          <div
            key={message.id}
            className={`${
              message.sender === currentUserId ? "text-right" : "text-left"
            } mb-2`}
          >
            <span className="inline-block bg-gray-300 rounded-lg p-2 text-gray-600">
              {message.text}
            </span>
            <br />
            <span className="text-xs text-white">
              {new Date(message.timestamp).toLocaleString()}
            </span>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <div className="relative">
          <textarea
            className="w-full p-1 rounded-lg bg-black bg-opacity-40 text-white"
            placeholder="Type your message..."
            value={inputValue}
            onChange={handleMessageChange}
            rows={3}
          ></textarea>
          <button
            className="absolute right-4 bottom-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
            onClick={toggleEmojiPicker}
          >
            😊
          </button>
          {showEmojiPicker && (
            <div className="absolute bottom-16 right-0 bg-white p-2 rounded-lg shadow-md">
              {emojis.map((emoji, index) => (
                <span
                  key={index}
                  onClick={() => handleEmojiSelect(emoji)}
                  style={{ cursor: "pointer" }}
                >
                  {emoji}
                </span>
              ))}
            </div>
          )}
        </div>
        <button
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          onClick={sendMessage}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
