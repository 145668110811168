// App.jsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Components/HomePage";
import AddUser from "./Components/AddUser";
import Combine from "./Components/Combine";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HappyCustomers from "./Components/HappyCustomers";
import AboutAndServices from "./Components/AboutandSevices";
import Login from "./Components/LoginPage";
const App = () => {
  return (
    <div className="flex flex-col min-h-screen text-white bg-gray-900">
      <Header />
      {/* Main Content */}
      <main className="flex-grow mt-20">
        {" "}
        {/* Added margin-top to avoid content hiding behind header */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomePage />
                <AboutAndServices />
                <HappyCustomers />
              </>
            }
          />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/chats" element={<Combine />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
