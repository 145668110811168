import React from "react";

const Footer = () => {
  return (
    <div>
      {/* Footer */}
      <footer className="bg-black opacity-80 text-white text-center py-4">
        <div className="container mx-auto">
          <p className="mb-2">Stay Connected</p>
          <div className="flex justify-center mb-4">
            <a href="#" className="mr-4 hover:text-gray-400">
              Facebook
            </a>
            <a href="#" className="mr-4 hover:text-gray-400">
              Twitter
            </a>
            <a href="#" className="mr-4 hover:text-gray-400">
              Instagram
            </a>
            <a href="#" className="hover:text-gray-400">
              LinkedIn
            </a>
          </div>
          <p>
            &copy; {new Date().getFullYear()} StreamSpace. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
