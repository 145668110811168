import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      // Attempt to login the user
      const response = await axios.post(
        "http://43.204.128.28/api/Chat/login",
        { email: formData.email, password: formData.password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMessage("Login successful");
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setMessage("User not found. Redirecting to add user...");
        setTimeout(() => {
          navigate("/add-user");
        }, 2000);
      } else {
        handleErrorResponse(err);
      }
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const { email, password } = formData;
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!password.trim()) {
      errors.password = "Password is required.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleErrorResponse = (err) => {
    if (err.response) {
      if (err.response.status === 400) {
        setErrors(err.response.data.errors);
      } else {
        setMessage("Error during login. Please try again later.");
      }
    } else {
      setMessage("Network error. Please check your internet connection.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-purple-900 to-blue-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold mb-6 text-center text-white">
          Login
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-200"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className={`mt-1 p-3 w-full bg-transparent border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } rounded-md transition-colors focus:border-blue-500 focus:outline-none text-white`}
              required
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-200"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              className={`mt-1 p-3 w-full bg-transparent border ${
                errors.password ? "border-red-500" : "border-gray-300"
              } rounded-md transition-colors focus:border-blue-500 focus:outline-none text-white`}
              required
            />
            {errors.password && (
              <p className="mt-1 text-sm text-red-500">{errors.password}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full py-3 px-6 bg-blue-600 text-lg rounded-md text-white hover:bg-blue-700 transition-all"
            disabled={loading}
          >
            {loading ? (
              <div className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.155 0-4.118-.842-5.657-2.343l1.414-1.414z"
                  ></path>
                </svg>
                Logging in...
              </div>
            ) : (
              "Login"
            )}
          </button>
        </form>
        {message && (
          <p className="mt-4 text-green-500 text-center">{message}</p>
        )}
        <p className="mt-4 text-gray-300 text-center">
          New user?{" "}
          <Link to="/add-user" className="text-blue-500 hover:underline">
            Click here to register
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
