import React from "react";
import Sidebar from "./SideBar";
import ChatWindow from "./ChatWindow";

const Combine = () => {
  const containerStyle = {
    backgroundImage: `url("https://i.pinimg.com/736x/02/cd/cb/02cdcb9f209a394a37d72db599bc388a.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      style={containerStyle}
      className="flex h-screen bg-gradient-to-r from-purple-900 to-blue-900"
    >
      <Sidebar />
      <ChatWindow />
    </div>
  );
};

export default Combine;
