// Header.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-black bg-opacity-80 shadow-md z-50">
      <div className="container mx-auto flex justify-between items-center py-4 px-6">
        <Link to="/" className="text-3xl font-bold text-white">
          StreamSpace
        </Link>
        <nav className="flex-grow flex justify-center space-x-4">
          <ScrollLink
            to="home"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white hover:bg-gray-700 px-4 py-2 rounded-md transition-colors duration-300 cursor-pointer"
          >
            Home
          </ScrollLink>
          <ScrollLink
            to="aboutandservices"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white hover:bg-gray-700 px-4 py-2 rounded-md transition-colors duration-300 cursor-pointer"
          >
            About Us
          </ScrollLink>

          <ScrollLink
            to="happy-customers"
            smooth={true}
            duration={500}
            className="text-gray-300 hover:text-white hover:bg-gray-700 px-4 py-2 rounded-md transition-colors duration-300 cursor-pointer"
          >
            Happy Customers
          </ScrollLink>
        </nav>
        <div className="flex space-x-4">
          <Link
            to="/login"
            className="text-gray-300 hover:text-white hover:bg-gray-700 px-4 py-2 rounded-md transition-colors duration-300"
          >
            Login
          </Link>
          <Link
            to="/add-user"
            className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-md transition-colors duration-300"
          >
            Signup
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
