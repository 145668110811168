import React from "react";

const AboutAndServices = () => {
  return (
    <div
      className="section bg-gradient-to-r from-purple-800 to-blue-900 text-white py-12"
      id="aboutandservices"
    >
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* About Us */}
          <div>
            <h2 className="text-3xl lg:text-4xl font-bold mb-8">About Us</h2>
            <p className="text-gray-300 mb-6">
              Welcome to our company. At StreamSpace, we are dedicated to
              providing exceptional services to our clients. Our team is
              passionate about delivering innovative solutions and exceeding
              expectations.
            </p>
            <p className="text-gray-300 mb-6">
              Our mission is to deliver high-quality results tailored to the
              unique needs of each client. With years of experience in the
              industry, we have built a reputation for excellence and customer
              satisfaction.
            </p>
            <p className="text-gray-300">
              We value collaboration, integrity, and professionalism in
              everything we do. Whether you're a small startup or a large
              corporation, we are committed to helping you achieve your goals.
            </p>
          </div>
          {/* Separator Line */}

          {/* Our Services */}
          <div>
            <h2 className="text-3xl lg:text-4xl font-bold mb-8">
              Our Services
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              {/* Service 1 */}
              <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Service 1
                </h3>
                <p className="text-gray-300">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  et elit vel ante varius suscipit. Sed auctor ex non ipsum
                  convallis.
                </p>
              </div>
              {/* Service 2 */}
              <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
                <h3 className="text-2xl font-semibold text-white mb-4">
                  Service 2
                </h3>
                <p className="text-gray-300">
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas. Duis eget leo eu magna
                  varius dictum nec eget turpis.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAndServices;
