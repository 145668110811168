import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated, config } from "react-spring";
import DemoChatWindow from "./DemoChatWindow";

const HomePage = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);

  const handleChatNowClick = () => {
    navigate("/add-user");
  };

  const handleOpenChatClick = () => {
    navigate("/chats"); // Adjust the route according to your actual chat application route
  };

  useEffect(() => {
    // Predefined demo messages
    const demoMessages = [
      { id: 1, text: "Hi there!" },
      { id: 2, text: "This is a demo chat." },
      { id: 3, text: "Feel free to ask anything." },
      { id: 4, text: "I'm here to help!" },
    ];

    setMessages(demoMessages);
  }, []);

  const contentAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 500 },
  });

  const demoAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(-50px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 500, delay: 200 },
  });

  const additionalContentAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: config.gentle,
    delay: 300,
  });

  return (
    <animated.div
      style={contentAnimation}
      className="flex flex-col md:flex-row items-center justify-center min-h-screen bg-gradient-to-r from-purple-900 to-blue-900 text-white"
    >
      
      <div
        id="home"
        className="max-w-lg mx-auto text-center md:p-8 md:pr-4 bg-gray-800 bg-opacity-80 rounded-lg shadow-lg md:ml-12"
      >
        <h1 className="text-3xl md:text-5xl font-bold mb-4 tracking-wide">
          Welcome to StreamSpace
        </h1>
        <p className="text-lg md:text-xl mb-8">
          Experience seamless communication with StreamSpace.
        </p>

        <animated.div
          style={additionalContentAnimation}
          className="mt-8 md:mt-12"
        >
          <div className="p-4 bg-gray-800 bg-opacity-80 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold mb-4">
              Experience Easy Chat with StreamSpace
            </h2>
            <p className="text-lg mb-8">
              Experience the ease of communication with the StreamSpace chat
              app.
            </p>
            <button
              onClick={handleChatNowClick}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Register (Now)
            </button>
            <button
              onClick={handleOpenChatClick}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-4"
            >
              Open Chat Application
            </button>
          </div>
        </animated.div>
      </div>

      <animated.div
        style={demoAnimation}
        className="hidden md:block md:w-1/3 md:ml-6 rounded-lg overflow-hidden"
      >
        <div className="p-4">
          <DemoChatWindow messages={messages} />
        </div>
      </animated.div>
    </animated.div>
  );
};

export default HomePage;
